<template>
  <v-img v-bind="$attrs" />
</template>

<script>
export default {
  name: 'BaseImage',
  inheritAttrs: false,
}
</script>
