<template>
  <v-divider v-bind="$attrs" />
</template>

<script>
export default {
  name: 'BaseDivider',
  inheritAttrs: false,
}
</script>
